footer {
	padding: 1.875rem .9375rem;
	background: $ci-color2;
	> .container {
		> div:first-of-type {
			margin-bottom: 2rem; } }
	h2 {
		color: $white; }
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			width: 48%;
			display: inline-block; } }
	p {
		color: $white; }
	a {
		font-size: 1rem;
		color: $white;
		&:hover {
			text-decoration: underline;
			color: $white; } }
	button, a {
		&.btn.btn-call-to-action {
			border: 1px solid $white;
			color: $white;
			width: 250px;
			text-align: center;
			margin: 0 0 1rem 0; } }
	img {
		display: block;
		margin: 0 auto 30px auto; }
	.sharing {
		a {
			display: block;
			margin-bottom: 1rem;
			font-size: .9rem; }
		img {
			width: 29px;
			height: auto;
			display: inline-block;
			margin: 0 .5rem 0 0; } }
	@media screen and (min-width: $screen-md-min) {
		> .container {
			> div:first-of-type {
				margin-bottom: 0; } } } }
