.row {
	&.header {
		.dh-seniorenmagazinhamburg-slider {
			@media screen and (max-width: $screen-md-min) {
				margin: 0; } } } }
.dh-seniorenmagazinhamburg-slider {
	> .dh-seniorenmagazinhamburg-onecolumn {
		margin-bottom: 0;
		.bx-controls {
			margin-top: 2rem;
			text-align: center;
			.bx-pager-item {
				display: inline-block;
				a {
					display: block;
					padding: .25rem .5rem;
					background: $ci-color2;
					color: $white;
					text-decoration: none;
					&.active, &:hover {
						background: $white;
						color: $ci-color2; } } } } }
	.carousel-inner {
		height: 315px;
		overflow: hidden;
		.dh-seniorenmagazinhamburg-slideritem {
			width: 100%;
			height: 100%;
			position: relative;
			img {
				width: 100%;
				height: auto; }
			> div {
				background-position: center;
				max-height: 315px;
				position: relative;
				width: 100%;
				height: 100%;
				background-size: cover; } } } }
