.col-md-2-4 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	width: 50%;
	float: left;
	@media screen and (min-width: $screen-sm-min) {
		width: 20%; } }

.col-md-6, .col-md-5 {
	&.with--form {
		.content {
			background: #f7f8fa;
			@media screen and (min-width: $screen-md-min) {
				float: right; } } } }
