.dh-seniorenmagazinhamburg-onecolumn, .dh-seniorenmagazinhamburg-twocolumns {
	//@media screen and (min-width: $screen-md-min)
	//	overflow: hidden
	//	padding-left: 15px
 }	//	padding-right: 15px

.dh-seniorenmagazinhamburg-twocolumns {
	&.is-child {
		padding: 0;
		> div:first-of-type {
			padding-left: 0; } } }
