div.top-bar {
	background-color: #d9d9d9;
	height: 30px;
	p {
		line-height: 30px;
		margin-bottom: 0;
		span {
			padding: 0 10px;
			background: $color-grey-light;
			display: inline-block;
			&:hover {
				cursor: pointer; }
			&.active {
				font-weight: bold; }
			&:nth-child(1) {
				margin-left: 10px;
				font-size: .875rem; }
			&:nth-child(2) {
				font-size: .9375rem; }
			&:nth-child(3) {
				font-size: 1rem; } } } }

div.navigation {
	background-color: $color-grey-light;
	border-bottom: 2px solid $ci-color2; }

header {
	position: absolute;
	width: 100%;
	z-index: 10;
	top: 0; }

nav.main--navigation.navbar--seniorenmagazinhamburg {
	> a {
		display: inline-block;
		&.logo {
			img {
				height: 70px;
				width: 83px;
				max-width: 83px;
				margin: 5px 0;
				@-moz-document url-prefix() {
					max-width: initial; } } } }
	.open--submenu {
		display: block;
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid #ccc;
		margin: 0 auto 5px auto;
		transform: rotate(180deg); }
	> .open--navigation {
		position: absolute;
		z-index: 1;
		height: 70px;
		right: 1rem;
		@include clearfix;
		.text--menu {
			position: absolute;
			bottom: 0;
			right: 5px;
			text-transform: uppercase;
			font-size: .75rem; } }
	.burger--menu {
		margin-top: 18px;
		width: 45px;
		height: 30px;
		position: relative;
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;
		span {
			display: block;
			position: absolute;
			height: 5px;
			width: 100%;
			background: #979797;
			border-radius: 0;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
			&:nth-child(1) {
				top: 0; }
			&:nth-child(2),&:nth-child(3) {
				top: 12px; }
			&:nth-child(4) {
				top: 24px; } }
		&.open span:nth-child(1) {
				top: 18px;
				width: 0;
				left: 50%; }
		&.open span:nth-child(2) {
			transform: rotate(45deg); }
		&.open span:nth-child(3) {
			transform: rotate(-45deg); }
		&.open span:nth-child(4) {
			top: 18px;
			width: 0;
			left: 50%; } }
	.navigation--bar {
		transition: all .5s ease-out;
		.mobile--navigation {
			background: $color-grey-light;
			margin-top: -10px;
			padding: 1rem 10%;
			> a.logo {
				overflow: hidden;
				display: inline-block;
				img {
					margin: 10px 0;
					width: 90px; } }
			.burger--menu {
				position: absolute;
				right: 10%;
				top: 2rem;
				margin-top: 0; } }
		ul.navigation {
			margin: 0;
			padding: 0;
			> li {
				margin: 0;
				padding: 0;
				position: relative;
				display: block;
				&:first-child {
					margin-top: 2rem; }
				> a {
					padding: 1rem .75rem .6rem;
					border-right: 1px solid rgba(255,255,255,0.2);
					border-radius: 0;
					font-size: .8rem;
					display: block;
					line-height: 1;
					text-transform: uppercase;
					&:hover {
						text-decoration: none; } }
				ul {
					&.dropdown {
						a {
							&:hover {
								color: $white;
								background: $ci-color2; } } } }
				ul {
					&.dropdown {
						display: none;
						margin: 0;
						padding: 0;
						list-style-type: none;
						z-index: 10;
						min-width: 100%;
						color: #979797;
						&.open {
							display: block; }
						> li {
							> a {
								display: block;
								color: #444;
								padding: .25rem 1.5rem;
								font-size: .8rem;
								line-height: 2rem;
								&:hover {
									text-decoration: none; } } } } } } } }

	//@media screen and (max-width: $screen-xs-min)
	//@media screen and (min-width: $screen-xs-min + 1) and (max-width: $screen-md-min + 1)
	@media screen and (max-width: $screen-md-min) {
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		> a.logo {
			img {
				margin: 5px 0; } }
		.navigation--bar {
			width: 100%;
			height: 100vh;
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
			background: $color-grey-dark;
			margin: 0;
			padding: 0;
			transform: translateX(100%);
			&.opened {
				overflow-y: scroll;
				transform: translateX(0); }
			.mobile--navigation {
				.burger--menu {
					top: 2.5rem; } }
			ul.navigation {
				overflow-y: auto;
				width: 100%;
				margin-bottom: 40px;
				> li {
					&.current {
						> a {
							color: $ci-color2; } }
					&.open {
						> a {
							color: $ci-color2; } }
					&.with--submenu {
						> .submenu--control {
							position: absolute;
							top: 0;
							right: 10%;
							width: 40px;
							height: 40px;
							cursor: pointer;
							&:hover {
								> span {
									border-top-color: $ci-color2; } }
							> span {
								margin-top: 1rem; }
							&.open {
								.open--submenu {
									border-top-color: $ci-color2;
									transform: rotate(0deg); } } } }
					a {
						color: $white;
						border-right: none;
						width: 80%;
						margin: 0 auto;
						display: block;
						height: 40px;
						&.active {
							color: $ci-color2; } }
					ul.dropdown {
						> li {
							> a {
								color: $white;
								&.current {
									color: $ci-color2; } } } } } } } }

	@media screen and (min-width: $screen-md-min) {
		display: flex;
		height: 80px;
		> .open--navigation {
			display: none; }
		.mobile--navigation {
			display: none; }
		ul.navigation {
			> li {
				&.current, &.active {
					a.current {
						color: $white; }
					> a {
						color: $white; }
					.open--submenu {
						border-top-color: $white; } } } }
		.mobile--menu {
			display: none; }
		> a {
			margin-right: 1rem;
			display: flex;
			align-items: center;
			justify-content: center; }
		.open--submenu {
			display: none; } }

	@media screen and (min-width: $screen-md-min + 1) {
		.navigation--bar {
			display: flex;
			ul.navigation {
				display: flex;
				align-items: flex-end;
				> li {
					display: inline-block;
					margin: 0 4px 0 0;
					&:hover {
						ul.dropdown {
							display: block;
							max-height: calc(100vh - 112px);
							overflow-y: auto;
							overflow-x: hidden; } }
					&:hover > a, a.active, a.current {
						border-top-color: $ci-color2;
						color: $white;
						background: $ci-color2;
						ul.dropdown {
							box-shadow: 0 0 0 2px $ci-color2;
							a:hover {
								background: $ci-color2; } } }
					> a {
						border-radius: .3rem .3rem 0 0;
						border-right: none;
						color: #444;
						font-weight: bold;
						&.current {
							box-shadow: 0 0 0 2px $ci-color2; } }
					ul.dropdown {
						position: absolute;
						background: $white;
						box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
						width: max-content;
						a.active {
							color: $white; }
						> li {
							&:not(:last-of-type) {
								border-bottom: 1px solid $ci-color2; }
							> a {
								padding: .25rem .5rem;
								font-size: .9rem;
								min-width: 165px;
								&.current {
									color: $white; } } } } } } } } }
