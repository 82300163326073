::selection {
	color: $white;
	background: $ci-color2; }
html {
	font-size: 100%;
	height: 100%; }

body[data-font='0'] {
	.page--content {
		p {
			font-size: .875rem; } } }
body[data-font='1'] {
	.page--content {
		p {
			font-size: .9375rem; } } }
body[data-font='2'] {
	.page--content {
		p {
			font-size: 1rem; } } }

/* libre-franklin-regular - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src: url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-regular.eot');
  src: local(""), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-regular.eot?#iefix') format("embedded-opentype"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-regular.woff2') format("woff2"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-regular.woff') format("woff"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-regular.ttf') format("truetype"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-regular.svg#LibreFranklin') format("svg"); }

@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  src: url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-700.eot');
  src: local(""), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-700.eot?#iefix') format("embedded-opentype"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-700.woff2') format("woff2"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-700.woff') format("woff"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-700.ttf') format("truetype"), url('/_Resources/Static/Packages/DH.SeniorenMagazinHamburg/fonts/libre-franklin-v13-latin/libre-franklin-v13-latin-700.svg#LibreFranklin') format("svg"); }

body {
	position: relative;
	height: 100%;
	font-family: 'Libre Franklin', sans-serif;
	font-size: 1.125rem;
	line-height: 1.5;
	overflow: hidden;
	padding-top: 120px;
	&.neos-backend-open {
		margin-top: auto !important; }
	&.start-page {
		.row.dh-seniorenmagazinhamburg-onecolumn:first-child {
			.content {
				background: $ci-color2;
				padding: 1rem 2rem;
				margin: 2rem 0;
				p, h1 {
					color: $white; } } } }
	> .body--wrapper {
		height: 100%;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
			background: $white; }
		&::-webkit-scrollbar-thumb {
			background: $ci-color2; }
		&::-webkit-scrollbar-track-piece {
			background: $white; } }
	.page--content {
		background-color: rgb(254, 254, 254);
		position: relative;
		left: 0; }
	h1 {
		margin: 1.5rem 0;
		padding: 0;
		font-size: 2rem;
		line-height: 2.5rem;
		color: $ci-color;
		font-weight: bold; }
	h2 {
		margin: 0 0 1rem 0;
		font-size: 1.75rem;
		line-height: 2rem;
		color: $ci-color;
		font-weight: bold; }
	h3 {
		margin: .75rem 0 .75rem 0;
		font-size: 1.1875rem;
		line-height: 1.75rem;
		color: $ci-color;
		font-weight: bold; }
	h4 {
		font-size: 1.125rem;
		line-height: 1.5rem;
		color: $ci-color;
		font-weight: bold; }
	p {
		font-size: .875rem;
		letter-spacing: .06rem;
		line-height: 1.5rem; }
	strong {
		> em {
			color: $ci-color;
			font-style: normal; } }
	ul {
		margin: 0;
		padding: 0;
		list-style-position: inside;
		li {
			letter-spacing: .06rem; } }
	span {
		&.font--switcher {
			font-size: .875rem;
			&.bigger {
				font-size: 1rem; } } }
	.has-img-responsive {
		img {
			display: block;
			max-width: 100%;
			height: auto; } }
	.content {
		ul, ol {
			list-style-position: inside;
			li {
				font-size: .875rem;
				line-height: 1.5rem; } }
		a {
			font-weight: bold;
			font-size: .8rem;
			color: $ci-color2; } }
	&.article {
		.introduction {
			margin-bottom: 30px; }
		.article--sharing {
			ul {
				list-style-type: none;
				li {
					float: left;
					@include clearfix;
					&:first-of-type {
						margin-right: .5rem; } } } } }

	@media screen and (max-width: $screen-md-min) {
		> .container {
			padding: 0;
			margin: 0;
			width: 100%; } }
	@media screen and (min-width: $screen-sm-min) {
		.page--content {
			> .content {
				padding: 0 15px 15px; } } }
	@media screen and (min-width: $screen-md-min) {
		&.start-page {
			.page--content {
				> .content {
					padding: 0;
					> div {
						> div:not(:first-child) {
							padding: 0 105px 30px 105px; } } } } }
		padding-top: 107px;
		.page--content {
			> .content {
				padding: 0 90px 30px 90px; } } } }
