@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both; } }

@mixin alpha-background-color($color, $background) {
	$percent: alpha($color) * 100%;
	$opaque: opacify($color, 1);
	$solid-color: mix($opaque, $background, $percent);
	background-color: $solid-color;
	background-color: $color; }
