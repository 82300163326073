.dh-seniorenmagazinhamburg-magazineoverview {
	.bx-wrapper {
		position: relative;
		margin: 0 auto; }
	> .bx-wrapper {
		padding: 15px 0;
		.magazine--item {
			p.title {
				font-size: 1rem;
				@media screen and (max-width: $screen-md-min) {
					margin-top: 10px; } } } }
	.bx-controls {
		position: absolute;
		top: 45%;
		width: 100%;
		.bx-controls-direction a {
			position: absolute;
			background: $ci-color2;
			font-size: 1rem;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
			padding-top: 1px;
			color: $white;
			&:hover, &:focus {
				text-decoration: none; } }
		.bx-prev {
			left: -30px; }
		.bx-next {
			right: -30px; } }
	.magazines--container {
		margin-bottom: 15px;
		position: relative;
		padding: 10px 20px 20px 20px;
		background: $white;
		border-top: none;
		.magazines--pager--info {
			margin-bottom: 10px;
			text-align: center;
			width: 100%;
			font-size: 1rem;
			font-weight: bold;
			color: $ci-color;
			span {
				padding: 0 10px 0 10px;
				background: #fff; } }
		#magazine--pager {
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				padding: 5px;
				a {
					margin: 1px 0 0 0;
					padding: 6px;
					background: $white;
					background: #fff;
					-moz-box-shadow: 0 1px 3px #666;
					-webkit-box-shadow: 0 1px 3px #666;
					box-shadow: 0 1px 3px #666;
					filter: progid:DXImageTransform.Microsoft.Shadow(color='#969696', Direction=145, Strength=2);
					display: block;
					&:hover {
						margin: 0;
						text-decoration: none; }
					p {
						padding: .2rem 0 0 0;
						color: #202020;
						font-size: .85rem;
						margin: 0; } } } } } }

.dh-seniorenmagazinhamburg-articleplugin {
	> .row--container {
		@media screen and (min-width: $screen-md-min) {
			overflow: hidden;
			padding: 0 15px; } } }

.dh-seniorenmagazinhamburg-interview {
	span.chat-icon {
		display: block;
		height: 100px;
		width: 100px;
		margin: 0 auto 30px auto; } }

.dh-seniorenmagazinhamburg-lightboxgallery {
	a {
		position: relative;
		margin-bottom: 30px;
		display: block;
		&:hover {
			.start-gallery {
				opacity: 1;
				@include alpha-background-color(rgba($ci-color2, 0.9), white); } }
		.start-gallery {
			opacity: 0;
			position: absolute;
			top: 0;
			background: none;
			width: 100%;
			height: 100%;
			transition: all 0.5s ease;
			display: flex;
			justify-content: center;
			align-items: center;
			> span {
				svg {
					width: 25px;
					height: 25px; } } } } }

#gdpr-cc-btn-accept {
	background-color: #7dff00;
	border-width: 1px; }
