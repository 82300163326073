.dh-seniorenmagazinhamburg-articleplugin {
	img {
		width: 100%; }
	a.read--article {
		position: relative;
		display: block;
		&:hover {
			.article--overlay {
				opacity: 1;
				visibility: visible; } }
		.article--overlay {
			transition: all .5s ease;
			opacity: 0;
			visibility: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			@include alpha-background-color(rgba($ci-color2, 0.9), white);
			//background: rgba(0,0,0,.7)
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			bottom: 0;
			color: $white;
			font-size: 1rem;
			z-index: 10; }
		.article--sponsored {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			right: 0;
			span {
				@include alpha-background-color(rgba(red, 0.8), white);
				color: $white;
				margin: 10px;
				padding: 3px 10px;
				display: block;
				font-size: .75rem; } } }
	.article--item {
		position: relative; }
	.article--teaser {
		background: #F1F1F1;
		margin-bottom: 20px;
		height: 100%;
		.article--description {
			padding: 15px;
			p {
				color: $text-color;
				font-weight: normal;
				margin-bottom: 3rem; }
			a.btn-article--link {
				position: absolute;
				bottom: 1rem; }
			span.icon-arrow {
				position: relative;
				width: 15px;
				height: 15px;
				display: inline-block;
				svg {
					position: absolute;
					top: 3px;
					left: -5px; } } } }

	@media screen and (min-width: $screen-md-min) {
		> .row {
			display: flex;
			display: -webkit-flex;
			> div {
				display: flex;
				display: -webkit-flex;
				flex-direction: column; } }
		.article--teaser {
			.article--description {
				a.btn-article--link {
					bottom: 2rem; } } } } }
