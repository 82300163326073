.btn {
	padding: 10px 50px;
	background: none;
	border-width: 1px;
	border-radius: 5px;
	font-size: 1rem;
	font-weight: bold;
	display: inline-block;
	outline: none;
	&:hover, &:active, &:focus, &:active:focus {
		text-decoration: none; }
	&.btn-read {
		border: 2px solid $ci-color2;
		color: $ci-color2; }
	&.btn-submit {
		border: 2px solid $ci-color2;
		color: $ci-color2; }
	&.btn-call-to-action {
		font-size: 1rem;
		margin: 1rem 0;
		color: $ci-color2;
		border: 2px solid $ci-color2;
		&:hover, &:active, &:focus, &:active:focus {
			background: $ci-color2;
			color: $white; }
		&.white {
			color: $white;
			border-color: $white;
			background: $ci-color2;
			&:hover, &:active, &:focus, &:active:focus {
				background: $white;
				color: $ci-color2; } }
		&.backend {
			min-width: 150px; } }
	&.btn-article--link {
		padding: 5px 25px;
		border: 2px solid $ci-color2;
		color: $ci-color2;
		&:hover, &:active, &:focus, &:active:focus {
			background: $ci-color2;
			color: $white;
			svg {
				polygon {
					fill: $white; } } } } }
