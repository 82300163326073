#contact-form {
	.input--container {
		margin-bottom: 15px;
		&.error {
			input {
				border: 1px solid red; } }
		label {
			font-weight: normal;
			font-size: .875rem;
			letter-spacing: .06rem;
			line-height: 1.5rem;
			&:hover {
				cursor: pointer; } }
		input {
			display: block;
			width: 100%;
			outline: none; }
		button {
			outline: none; }
		textarea {
			width: 100%;
			resize: vertical; } } }
